import {
    danger,
  darkBorder,
  lightBackground,
  lightBackgroundBlue,
  primary,
} from 'library/colors';
import styled from 'styled-components';
import { IScheduleCall } from '../organisms/phoneBook';
import React, { useState } from 'react';
import { IUser } from 'library/interfaces/crdInterfaces';

interface IScheduleCallProps {
  call: IScheduleCall;
  users: IUser[]
  notes: string;
  handleEditButtonClick: (call: IScheduleCall) => void;
  deleteScheduleCall: (campaignId: string, callId: string) => void;
}
const ScheduleCall = ({
  call,
  notes,
  users,
  handleEditButtonClick,
  deleteScheduleCall,
}: IScheduleCallProps) => {

    const [showNotes, setShowNotes] = useState(false)

    const userName = users.find(usr=>usr.exten === call.agent)?.name || ''
  return (
    <ScheduleCont>
      <div>{userName}</div>
      <div>{call.contact}</div>
      <div>{call.contact_datetime}</div>
      <CloseButton danger={false} onClick={() => setShowNotes(true)}>
        <i className="bx bxs-show"></i>
      </CloseButton>
      <CloseButton danger={false} onClick={() => handleEditButtonClick(call)}>
        <i className="bx bx-pencil"></i>
      </CloseButton>
      <CloseButton
        danger={true}
        onClick={() => deleteScheduleCall(call.campaign_id, call.id)}
      >
        <i className="bx bx-trash"></i>
      </CloseButton>
      {showNotes && <NotesBackCont>
        <NotesCont>
        <Exit onClick={()=>setShowNotes(false)}>X</Exit>
          <NotesTitle>Notas para la llamada</NotesTitle>
          <NotesContent>{notes}</NotesContent>
        </NotesCont>
      </NotesBackCont>}
    </ScheduleCont>
  );
};

const ScheduleCont = styled.div`
  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 2fr 0.7fr 1fr 50px 50px 40px;
  align-items: center;
  background-color: ${lightBackground};
`;
const CloseButton = styled.div<ICloseButton>`
  color: #fff;
  text-align: center;
  flex-grow: 1;
  width: 40px;
  font-size: 1.2rem;
  line-height: 0;
  padding: 6px 0;
  border-radius: 50px;
  cursor: pointer;
  background-color: ${darkBorder};

  &:hover {
    background-color: ${props => (props.danger ? danger : primary)};
  }
`;
interface ICloseButton {
  danger: boolean;
}

const NotesBackCont = styled.div`
  /* border: 1px solid; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${lightBackgroundBlue};
  display: grid;
  place-content: center;
`;
const NotesCont = styled.div`
  width: 600px;
  height: 400px;
  padding: 10px 20px;
  background-color: ${lightBackground};
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(123, 153, 4, 0.5);
  position: relative;
`;
const NotesTitle = styled.div`
  text-align: center;
  font-size: 1rem;
`;
const NotesContent = styled.div`
  padding-top: 10px;
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${danger};
  }
`;
export default ScheduleCall;
