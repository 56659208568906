import {
  IUpdatePhoneDialedNumber,
  IUpdatePhoneIsCallAnswered,
  IUpdatePhoneIsConnected,
  IUpdatePhoneIsIncommingCall,
  IUpdatePhoneIsRegistered,
  IUpdatePhoneIsToMakeCall,
  IUpdatePhoneTimeCounter,
  IUpdatePhoneIsMuted,
  IUpdatePhoneIsHeld,
  IUpdatePhoneIncommingMessage,
  IUpdatePhoneIsTransferCall,
  UPDATE_PHONE_IS_CONECTED,
  UPDATE_PHONE_IS_REGISTERED,
  UPDATE_PHONE_IS_INCOMMING_CALL,
  UPDATE_PHONE_IS_CALL_ANSWERED,
  UPDATE_PHONE_IS_TO_MAKE_CALL,
  UPDATE_PHONE_DIALED_NUMBER,
  UPDATE_PHONE_TIME_COUNTER,
  UPDATE_PHONE_IS_MUTED,
  UPDATE_PHONE_IS_HELD,
  UPDATE_PHONE_INCOMMING_MESSAGE,
  UPDATE_PHONE_IS_TRANSFER_CALL,
  IUpdatePhoneCallId,
  UPDATE_PHONE_CALL_ID,
  IUpdatePhoneE64Data,
  UPDATE_PHONE_E64_DATA,
  IDeletePhoneE64Data,
  DELETE_PHONE_E64_DATA,
  IDeletePhoneCallId,
  DELETE_PHONE_CALL_ID,
  IUpdateBlindTransferExt,
  UPDATE_BLIND_TRANSFER_EXT,
  IUpdateBlindTransferExtList,
  UPDATE_BLIND_TRANSFER_EXT_LIST,
  IUpdatePhoneExtraButton,
  UPDATE_PHONE_EXTRA_BUTTON,
} from './actionTypes';

import { IPhoneMessage } from './reducer';

export const updateIsConectedAction = (
  isConnected: boolean,
): IUpdatePhoneIsConnected => ({
  type: UPDATE_PHONE_IS_CONECTED,
  payload: isConnected,
});

export const updateIsRegisteredAction = (
  isRegistered: boolean,
): IUpdatePhoneIsRegistered => ({
  type: UPDATE_PHONE_IS_REGISTERED,
  payload: isRegistered,
});

export const updateIsIncommingCallAction = (
  isIncomming: boolean | undefined,
): IUpdatePhoneIsIncommingCall => ({
  type: UPDATE_PHONE_IS_INCOMMING_CALL,
  payload: isIncomming,
});

export const updateIsCallAnsweredAction = (
  isAnswered: boolean,
): IUpdatePhoneIsCallAnswered => ({
  type: UPDATE_PHONE_IS_CALL_ANSWERED,
  payload: isAnswered,
});

export const updateIsToMakeCallAction = (
  isMakeACall: boolean | undefined,
): IUpdatePhoneIsToMakeCall => ({
  type: UPDATE_PHONE_IS_TO_MAKE_CALL,
  payload: isMakeACall,
});

export const updateDialedNumberAction = (
  dialedNumber: string,
): IUpdatePhoneDialedNumber => ({
  type: UPDATE_PHONE_DIALED_NUMBER,
  payload: dialedNumber,
});

export const updateTimeCounterAction = (
  counter: string,
): IUpdatePhoneTimeCounter => ({
  type: UPDATE_PHONE_TIME_COUNTER,
  payload: counter,
});

export const updateIsMutedAction = (isMuted: boolean): IUpdatePhoneIsMuted => ({
  type: UPDATE_PHONE_IS_MUTED,
  payload: isMuted,
});

export const updateIsHeldAction = (isHeld: boolean): IUpdatePhoneIsHeld => ({
  type: UPDATE_PHONE_IS_HELD,
  payload: isHeld,
});

export const updateIsTranferCall = (
  isTransferCall: boolean | undefined,
): IUpdatePhoneIsTransferCall => ({
  type: UPDATE_PHONE_IS_TRANSFER_CALL,
  payload: isTransferCall,
});

export const updateIncommingMessageAction = (
  message: IPhoneMessage,
): IUpdatePhoneIncommingMessage => ({
  type: UPDATE_PHONE_INCOMMING_MESSAGE,
  payload: message,
});

export const updatePhoneCallIdAction = (
  callid: string,
): IUpdatePhoneCallId => ({
  type: UPDATE_PHONE_CALL_ID,
  payload: callid,
});

export const updatePhoneE64DataAction = (
  e64_data: NonNullable<unknown>[],
): IUpdatePhoneE64Data => ({
  type: UPDATE_PHONE_E64_DATA,
  payload: e64_data,
});

export const deletePhoneE64DataAction = (): IDeletePhoneE64Data => ({
  type: DELETE_PHONE_E64_DATA,
});

export const deletePhoneCallIdAction = (): IDeletePhoneCallId => ({
  type: DELETE_PHONE_CALL_ID,
});

export const updateBlindTransferExt = (
  extension?: string,
): IUpdateBlindTransferExt => ({
  type: UPDATE_BLIND_TRANSFER_EXT,
  payload: extension,
});

export const updateBlindTransferExtList = (
  extentList?: { exten: string; label: string }[],
): IUpdateBlindTransferExtList => ({
  type: UPDATE_BLIND_TRANSFER_EXT_LIST,
  payload: extentList,
});

export const updatePhoneExtraButtonAction = (
  extraButtonData?: { exten: string; label: string },
): IUpdatePhoneExtraButton => ({
  type: UPDATE_PHONE_EXTRA_BUTTON,
  payload: extraButtonData,
});