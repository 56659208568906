import { del, get, post, put } from 'helpers/api_helper';
import {
  danger,
  darkBorder,
  darkGrayBg,
  lightBackground,
  lightBackgroundBlue,
  popUpBackground,
  primary,
} from 'library/colors';
import { useAppSelector } from 'library/hooks/useAppSelector';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import ScheduleCall from '../molecules/scheduleCall';
import { IReportData } from 'library/interfaces/crdInterfaces';
import {
  getDataByIndexIndexedDb,
  IIndexedDbCallsData,
} from 'library/indexedDb/indexedDB';
import { Phone } from 'library/phone/phone';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { updateDialedNumberAction } from 'store/actions';

export interface IScheduleCall {
  contact: string;
  contact_datetime: string;
  e64_data: string;
  agent: string;
  id: string;
  campaign_id: string;
}
interface IPhoneBookProps {
  phone: Phone;
  setShowPhoneBook: (show: boolean) => void;
}
const PhoneBook = ({ setShowPhoneBook, phone }: IPhoneBookProps) => {

  const dispatch = useAppDispatch()
  const { roles, exten, pbx_url } = useAppSelector(store => store.authUserData);
  const campaigns = useAppSelector(store => store.voiceCampaigns.campaigns);
  const { users } = useAppSelector(
    store => store.cdr.reportData,
  ) as IReportData;
  const [scheduleCalls, setScheduleCalls] = useState<IScheduleCall[]>([]);

  const [historical, setHistorical] = useState<IIndexedDbCallsData[]>([]);

  // si existe esta id se esta editando
  const [scheduleCallId, setScheduleCallId] = useState('');

  const [campaign_id, setCampaign_id] = useState('');
  const [contact, setContact] = useState('');
  const [date, setDate] = useState('');
  const [hour, setHour] = useState('');
  const [notes, setNotes] = useState('');

  const handleCampaignChange = async (campaign_id: string) => {
    try {
      setCampaign_id(campaign_id);
      const isSupervisor = roles.some(rol => rol.name === 'supervisor');

      const string = isSupervisor
        ? `/${campaign_id}`
        : `/${campaign_id}/${exten}`;

      const agenda = await get(`/pbx/campaign/schedule/call${string}`);
      setScheduleCalls(agenda);
    } catch (error) {
      console.log(error);
      setScheduleCalls([]);
      toast.error('Hubo un error recuperando las llamadas agendadas');
    }
  };

  const handleCall = (number: string, server: string) => {
    try {
      dispatch(updateDialedNumberAction(number))
      phone.call(number, server)
      setShowPhoneBook(false)
    } catch (error) {
      console.log(error)
    }
  };

  const handleExit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!(event.target instanceof Element)) return;

    if (
      event.target.id === 'popupContainer' ||
      event.target.id === 'exitButton'
    ) {
      return setShowPhoneBook(false);
    }
  };

  function encodeToBase64Unicode(str: string): string {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
        return String.fromCharCode(parseInt(p1, 16));
      }),
    );
  }
  function decodeFromBase64Unicode(base64: string): string {
    return decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join(''),
    );
  }

  const saveScheduleCall = async () => {
    if (!campaign_id || !contact || !date || !hour)
      return toast.error('Faltan datos para agendar la llamada');
    const newSchedule: IScheduleCall = {
      agent: exten,
      campaign_id,
      contact,
      contact_datetime: `${date} ${hour}`,
      e64_data: encodeToBase64Unicode(JSON.stringify([{ notas: notes }])),
      id: crypto.randomUUID(),
    };
    try {
      await post('/pbx/campaign/schedule/call', newSchedule);
      setScheduleCalls(prev => [newSchedule, ...prev]);
      // setCampaign_id('');
      setContact('');
      setDate('');
      setHour('');
      setNotes('');
    } catch (error) {
      console.log(error);
      toast.error('Ha ocurrido un error al agendar la llamada');
    }
  };

  const editScheduleCall = async () => {
    if (!campaign_id || !contact || !date || !hour || !scheduleCallId)
      return toast.error('Faltan datos para editar la llamada');
    const newSchedule: IScheduleCall = {
      agent: exten,
      campaign_id,
      contact,
      contact_datetime: `${date} ${hour}`,
      e64_data: encodeToBase64Unicode(JSON.stringify([{ notas: notes }])),
      id: scheduleCallId,
    };
    try {
      await put(
        `/pbx/campaign/schedule/call/${campaign_id}/${scheduleCallId}`,
        newSchedule,
      );
      setScheduleCalls(prev =>
        prev.map(cal => {
          if (cal.id === scheduleCallId) return newSchedule;
          return cal;
        }),
      );
      // setCampaign_id('');
      setContact('');
      setDate('');
      setHour('');
      setNotes('');
      setScheduleCallId('');
    } catch (error) {
      console.log(error);
      toast.error('Ha ocurrido un error al agendar la llamada');
    }
  };

  const deleteScheduleCall = async (
    campaignId: string,
    scheduleCallId: string,
  ) => {
    try {
      setScheduleCalls(prev => prev.filter(sch => sch.id !== scheduleCallId));
      await del(`/pbx/campaign/schedule/call/${campaignId}/${scheduleCallId}`);
      setContact('');
      setDate('');
      setHour('');
      setNotes('');
      setScheduleCallId('');
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditButtonClick = (scheduleCall: IScheduleCall) => {
    const [date, hour] = scheduleCall.contact_datetime.split(' ');
    const nota = JSON.parse(decodeFromBase64Unicode(scheduleCall.e64_data))[0]
      .notas;
    setCampaign_id(scheduleCall.campaign_id);
    setContact(scheduleCall.contact);
    setDate(date);
    setHour(hour);
    //@ts-ignore
    setNotes(nota);
    setScheduleCallId(scheduleCall.id);
  };

  useEffect(() => {
    getDataByIndexIndexedDb(exten).then(res => {
      const reverse = res.reverse();

      setHistorical(reverse);
    });
  }, []);

  return (
    <PopUpContainer id="popupContainer" onClick={e => handleExit(e)}>
      <FormContainer>
        <Exit id="exitButton">X</Exit>
        <HistoryCont>
          {historical.map((call, index) => {
            return (
              <HistCallCont key={index}>
                <CallNumber>{call.number}</CallNumber>
                <IconsCont>
                  <Direction isIncoming={call.direction === 'incomming'}>
                    <i
                      className={`bx bxs-${
                        call.direction === 'incomming' ? 'down' : 'up'
                      }-arrow`}
                    />
                  </Direction>
                  <PhoneCall onClick={()=>handleCall(call.number, pbx_url)}>
                    <i className="bx bx-phone-call" />
                  </PhoneCall>
                </IconsCont>
              </HistCallCont>
            );
          })}
        </HistoryCont>
        <Agenda>
          <Form>
            <InputCont>
              <Label>Campaña</Label>
              <CampaignSelect
                value={campaign_id}
                onChange={e => handleCampaignChange(e.target.value)}
              >
                <option value="">Selecciona una campaña</option>
                {campaigns.map(camp => {
                  return (
                    <option key={camp.id} value={camp.id}>
                      {camp.name}
                    </option>
                  );
                })}
              </CampaignSelect>
            </InputCont>
            <InputCont>
              <Label>Contacto</Label>
              <Input
                type="text"
                placeholder="Numero"
                value={contact}
                onChange={e => setContact(e.target.value)}
              />
            </InputCont>
            <InputCont>
              <Label>Día</Label>
              <Input
                type="date"
                value={date}
                onChange={e => setDate(e.target.value)}
              />
            </InputCont>
            <InputCont>
              <Label>Hora</Label>
              <Input
                type="time"
                value={hour}
                onChange={e => setHour(e.target.value)}
              />
            </InputCont>
            <Notes
              placeholder=" Notas / Información extra"
              value={notes}
              onChange={e => setNotes(e.target.value)}
            />
            {!scheduleCallId && (
              <Button onClick={() => saveScheduleCall()}>Agendar</Button>
            )}
            {scheduleCallId && (
              <Button onClick={() => editScheduleCall()}>Editar</Button>
            )}
          </Form>
          <ScheduleCallsCont>
            {scheduleCalls.map((cal, index) => {
              const campaignName =
                campaigns.find(camp => camp.id === cal.campaign_id)?.name ||
                'Campaña';
              const nota = JSON.parse(decodeFromBase64Unicode(cal.e64_data))[0]
                .notas;
              return (
                <ScheduleCall
                  call={cal}
                  users={users}
                  notes={nota}
                  deleteScheduleCall={deleteScheduleCall}
                  handleEditButtonClick={handleEditButtonClick}
                  key={index}
                />
              );
            })}
          </ScheduleCallsCont>
        </Agenda>
      </FormContainer>
    </PopUpContainer>
  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 1000;
  background-color: ${popUpBackground};
`;
const FormContainer = styled.div`
  position: fixed;
  top: 100px;
  z-index: 10;
  width: 100%;
  max-width: 1000px;
  height: 100%;
  max-height: 80vh;
  color: black;
  padding: 30px 20px 20px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 10px;
  overflow: hidden;
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
const HistoryCont = styled.div`
  padding: 10px;
  border-radius: 10px;
  background-color: ${lightBackgroundBlue};
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
const HistCallCont = styled.div`
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${lightBackground};
`;
const IconsCont = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
const CallNumber = styled.div``;
const Direction = styled.div<IDirection>`
  color: ${props => (props.isIncoming ? primary : danger)};
`;
interface IDirection {
  isIncoming: boolean;
}
const PhoneCall = styled.div`
  cursor: pointer;
  color: #fff;
  /* aspect-ratio: 1; */
  padding: 5px 10px;
  border-radius: 50px;
  line-height: 0;
  background-color: ${darkBorder};
  
  &:hover {
    background-color: ${primary};
  }
`;

const Agenda = styled.div``;
const Form = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-gap: 10px;
  /* place-items: center; */
`;
const CampaignSelect = styled.select`
  width: 100%;
  padding: 3px 5px;
  border-radius: 5px;
  border: 1px solid ${primary};
  background-color: transparent;
`;
const InputCont = styled.div`
  /* border: 1px solid; */
`;
const Input = styled.input`
  width: 100%;
  height: 29px;
  padding: 3px 5px;
  border-radius: 5px;
  border: 1px solid ${primary};
`;
const Label = styled.label`
  margin: 0;
  padding: 0;
  /* line-height: 0; */
  font-size: 0.7rem;
  /* font-weight: 500; */
  color: ${darkGrayBg};
`;
const Notes = styled.textarea`
  grid-column: 1 / 4;
  resize: none;
  border-radius: 5px;
  border: 1px solid ${primary};
`;
const Button = styled.div`
  width: 100%;
  height: 45px;
  text-align: center;
  line-height: 43px;
  /* padding: 5px 0; */
  border-radius: 5px;
  font-size: 1rem;
  align-self: center;
  justify-self: center;
  color: white;
  cursor: pointer;
  background-color: ${primary};
`;
const ScheduleCallsCont = styled.div`
  margin-top: 20px;
  border-radius: 10px;
  height: 83%;
  padding: 10px;
  position: relative;
  background-color: ${lightBackgroundBlue};
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;

export default PhoneBook;
