import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';

// Import Routes all
import { authProtectedRoutes, publicRoutes } from './routes';

// Import all middleware
import Authmiddleware from './routes/route';

// layouts Format
import VerticalLayout from './components/VerticalLayout/';
import HorizontalLayout from './components/HorizontalLayout/';
import NonAuthLayout from './components/NonAuthLayout';

// Import scss
import './assets/scss/theme.scss';
import Loading from './components/Loading';
import { useAuth0 } from '@auth0/auth0-react';
import { Phone } from 'library/phone/phone';
import {
  getChatBotByCallAction,
  updateDialedNumberAction,
  updateIncommingMessageAction,
  updateIsCallAnsweredAction,
  updateIsConectedAction,
  updateIsHeldAction,
  updateIsIncommingCallAction,
  updateIsMutedAction,
  updateIsRegisteredAction,
  updateIsToMakeCallAction,
  updateIsTranferCall,
  updatePhoneCallIdAction,
  updatePhoneE64DataAction,
  updateTimeCounterAction,
  setSysNotifications,
  updateBlindTransferExt,
  updateBlindTransferExtList,
  deletePhoneE64DataAction,
  updatePhoneExtraButtonAction,
} from 'store/actions';
import {
  areNotificationsAlowed as areNotificationsAllowed,
  sysNotification,
} from 'library/notifications/notifications';
import { useTranslation } from 'react-i18next';
import useLocalStorage from 'library/hooks/useLocalStorage';
import Subscriptions from 'components/subscriptions/template/subscriptions';
import toast, { Toaster } from 'react-hot-toast';
import { toast20 } from 'library/toast/toastFunc';
import InfinitePhoneToast from 'library/toast/personalizedToastComponents/infinitePhoneToast';

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

//import fakeBackend from "./helpers/AuthType/fakeBackend"

// Activating fake backend
//fakeBackend()

/* const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  //databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
}; */

// init firebase backend
//initFirebaseBackend(firebaseConfig)

const App = props => {
  const { t } = useTranslation('notifications');
  const { isAuthenticated, isLoading } = useAuth0();

  const dispatch = useDispatch();
  const authUserData = useSelector(store => store.authUserData);
  const sysNotifications = useSelector(store => store.global.sysNotifications);

  const [sysNotificationsStored, setSysNotificationsStored] = useLocalStorage(
    'sysNotifications',
    {
      areAllowed: false,
      showPhoneIncomingCalls: false,
      showQueuedCallsNoAnswered: false,
      showNewWaSession: false,
      showNewWaMessage: false,
    },
  );

  const [phone, setPhone] = useState(undefined);

  const isSupervisor = useMemo(() => {
    return authUserData.roles.some(rol => rol.name === 'supervisor');
  }, [authUserData]);

  const handleNotifications = (appVisibility, showCallNotification) => {
    if (appVisibility === 'visible' || !showCallNotification) return;
    sysNotification(t('callNotification.title'), t('callNotification.body'));
  };

  // start phone configuration
  const callBacks = {
    onServerConnect: () => {
      dispatch(updateIsConectedAction(true));
      dispatch(updateIsRegisteredAction(false));
    },
    onServerDisconnect: () => {
      dispatch(updateIsConectedAction(false));
      dispatch(updateIsRegisteredAction(false));
    },
    onRegistered: () => {
      dispatch(updateIsRegisteredAction(true));
    },
    onUnregistered: () => {
      dispatch(updateIsRegisteredAction(false));
    },
    onCallCreated: xUuid4UniqueIdFromFront => {
      dispatch(updateIsIncommingCallAction(false));

      // xUuid4UniqueIdFromFront only exits in outgoing calls
      // if is an incomming call, xUuid4UniqueIdFromFront in undefined
      if (authUserData.owner !== 'CORPORACION') return;
      dispatch(updatePhoneCallIdAction(xUuid4UniqueIdFromFront));
    },
    onCallReceived: (headers, appVisibility, showCallNotification) => {
      dispatch(updateIsIncommingCallAction(true));

      // if(headers.e64_data){
      //   dispatch(updatePhoneE64DataAction(headers.e64_data))
      // }
      handleNotifications(appVisibility, showCallNotification);

      // TODO: try this
      if (authUserData.owner === 'CORPORACION') return;
      dispatch(getChatBotByCallAction(authUserData.owner, headers.uniqueId));
      dispatch(updatePhoneCallIdAction(headers.uniqueId));
    },
    onCallAnswered: headers => {
      dispatch(updateDialedNumberAction(''));
      dispatch(updateIsCallAnsweredAction(true));
      dispatch(updateTimeCounterAction('00:00:00'));
      dispatch(updateIsToMakeCallAction(undefined));

      if (headers.blindTransferExt) {
        dispatch(updateBlindTransferExt(headers.blindTransferExt));
      }
      if (headers.blindTransferExtList) {
        dispatch(updateBlindTransferExtList(headers.blindTransferExtList));
      }

      if (headers.showSuccess) {
        toast.success(headers.showSuccess);
      }
      if (headers.showInfo) {
        toast(headers.showInfo, { icon: '⚠️' });
      }
      if (headers.showError) {
        toast.error(headers.showError);
      }
      if (headers.showOutgoingCall) {
        toast(
          <p style={{ padding: '0 0 0 10px' }}>{headers.showOutgoingCall}</p>,
          {
            icon: '⚠️',
            duration: 10000,
            style: {
              fontSize: '1.5rem',
              minWidth: '550px',
            },
          },
        );
      }

      if (headers.rightBarAnyJsonData) {
        dispatch(updatePhoneE64DataAction(headers.rightBarAnyJsonData));
      } else {
        dispatch(deletePhoneE64DataAction([]));
      }

      if (headers.e64_data && Array.isArray(headers.e64_data) && headers.e64_data.length > 0) {
        dispatch(updatePhoneE64DataAction(headers.e64_data));
      } 

      if(headers.extraButtonData) {
        dispatch(updatePhoneExtraButtonAction(headers.extraButtonData))
      }

      // TODO: try this
      if (authUserData.owner !== 'CORPORACION') return;
      dispatch(updatePhoneE64DataAction(headers.e64_data));
      dispatch(
        updatePhoneCallIdAction(
          headers.uniqueId || headers.xUuid4UniqueIdFromFront,
        ),
      );
    },
    onCallHangup: () => {
      dispatch(updateDialedNumberAction(''));
      dispatch(updateIsCallAnsweredAction(false));
      dispatch(updateIsIncommingCallAction(undefined));
      dispatch(updateIsMutedAction(false));
      dispatch(updateIsHeldAction(false));
      dispatch(updateIsToMakeCallAction(undefined));
      // blindTransferExtList vuelve a ser undefined despues de que de hace la transferencia
      dispatch(updateBlindTransferExtList());
      dispatch(updatePhoneExtraButtonAction())
    },
    onTransferCallCreated: () => {
      dispatch(updateIsTranferCall(true));
    },
    onTransferCallAnswered: () => {
      dispatch(updateIsTranferCall(false));
    },
    onTransferCallHangup: () => {
      dispatch(updateIsTranferCall(undefined));
    },
    onConfirmTransfer: () => {
      dispatch(updateIsTranferCall(undefined));
    },
    onBlindTransfer: () => {
      dispatch(updateIsTranferCall(undefined));

      // blindTransferExtList vuelve a ser undefined despues de que de hace la transferencia
      dispatch(updateBlindTransferExtList());
    },
    onMessageReceived: message => {
      let data;
      try {
        data = JSON.parse(message);
      } catch (error) {
        console.log(error);
        toast.error(
          `Se ha recibido un mensaje vía sip con error.\n\n
          Tome un print o copie siguiente mensaje y envíelo al equipo de desarrollo!\n\n` +
            error.message,
          {
            duration: 20000,
          },
        );
        return;
      }
      if (
        (data.toast20 && Array.isArray(data.toast20)) ||
        typeof data.toast20 === 'string'
      ) {
        toast20({
          component: InfinitePhoneToast,
          componentMessage: data.toast20,
        });
      }
      // dispatch(updateIncommingMessageAction(Message));
    },
  };

  useEffect(() => {
    if (
      authUserData.auth0_id === '' ||
      authUserData.pbx_url === '' ||
      authUserData.exten === ''
      // phone !== undefined
    )
      return;

    const audioElement = document.getElementById('phoneAudioMedia');
    const transferAudioElement = document.getElementById(
      'phoneAudioMediaTransferSession',
    );
    const server = `wss://${authUserData.pbx_url}:${authUserData.pbx_port}/ws`;
    const config = {
      delegate: callBacks,
      aor: `sip:${authUserData.exten}@${authUserData.pbx_url}`,
      userAgentOptions: {
        displayName: authUserData.display_name,
        authorizationUsername: authUserData.exten,
        authorizationPassword: authUserData.exten_password,
        logBuiltinEnabled: false,
      },
      media: {
        remote: {
          mainSession: {
            audio: audioElement,
          },
          transferSession: {
            audio: transferAudioElement,
          },
        },
        constraints: {
          audio: true,
          video: false,
        },
      },
    };

    try {
      const instance = new Phone(server, config);
      instance.connect().then(() => instance.register());
      setPhone(instance);
    } catch (error) {
      console.log(error);
    }

    const element = document.getElementById('chargerClick');
    if (element) element.click();

    return () => {
      if (phone === undefined) return;
      phone.unregister();
      setPhone(undefined);
    };
  }, [authUserData]);
  // end phone configuration

  // notifications are handled with local storage
  useEffect(() => {
    if (authUserData.roles.length === 0) return;
    const areNotiAllowed = areNotificationsAllowed();

    // if app has permission to show notifications and user has "supervisor" rol
    // set sys notifications with local storage values
    if (areNotiAllowed && isSupervisor) {
      dispatch(
        setSysNotifications({
          ...sysNotificationsStored,
        }),
      );
      return;
    }

    // if app has permission to show notifications but user doesn't have "supervisor" rol
    // set sys notifications with local storage values and showQueuedCallsNoAnswered as false
    // because the user only can see incoming call notifications
    if (areNotiAllowed && !isSupervisor) {
      dispatch(
        setSysNotifications({
          ...sysNotificationsStored,
          showQueuedCallsNoAnswered: false,
        }),
      );
      return;
    }

    // if app doesn't have permission to show notifications
    // set all sys notifications as false
    dispatch(
      setSysNotifications({
        areAllowed: false,
        showPhoneIncomingCalls: false,
        showQueuedCallsNoAnswered: false,
      }),
    );
  }, [authUserData]);

  useEffect(() => {
    const handleSysNotificationsStored = () => {
      setSysNotificationsStored(sysNotifications);
    };
    window.addEventListener('beforeunload', handleSysNotificationsStored);
    return () => {
      window.removeEventListener('beforeunload', handleSysNotificationsStored);
    };
  }, [sysNotifications]);

  if (isLoading) {
    return <Loading />;
  }

  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case 'horizontal':
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  return (
    <React.Fragment>
      <audio id="phoneAudioMedia" />
      <audio id="phoneAudioMediaTransferSession" />
      {isSupervisor && (
        <Subscriptions
          authUserData={authUserData}
          sysNotifications={sysNotifications}
        />
      )}
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              isAuthenticated={isAuthenticated}
              exact
            />
          ))}
          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              phone={phone}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              isAuthenticated={isAuthenticated}
              exact
            />
          ))}
        </Switch>
      </Router>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        toastOptions={{ duration: 4000 }}
      />
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
