export const data = '';

const openIndexedDb = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('smartbot', 1);

    request.onupgradeneeded = event => {
      const db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains('calls')) {
        const store = db.createObjectStore('calls', {
          keyPath: 'id',
          autoIncrement: true,
        });
        store.createIndex('exten', 'exten', { unique: false });
      }
    };

    request.onsuccess = event => {
      const db = (event.target as IDBOpenDBRequest).result;
      resolve(db);
    };

    request.onerror = event => {
      reject(new Error('Error opening database'));
    };
  });
};

export interface IIndexedDbCallsData {
    exten: string;
    number: string;
    direction: 'incomming' | 'outgoing';
    date: string
}
export function addDataToIndexedDb(data: IIndexedDbCallsData) {
  openIndexedDb().then(db => {
    const transaction = db.transaction('calls', 'readwrite');
    const store = transaction.objectStore('calls');
    store.add(data);

    transaction.oncomplete = () => {
      console.log('Data added successfully');
    };

    transaction.onerror = () => {
      console.error('Error adding data');
    };
  });
}

export function getDataByIndexIndexedDb(indexValue: string): Promise<IIndexedDbCallsData[]> {
    return new Promise((resolve, reject) => {
      openIndexedDb().then(db => {
        const transaction = db.transaction('calls', 'readonly');
        const store = transaction.objectStore('calls');
        const index = store.index('exten'); 
  
        const results: IIndexedDbCallsData[] = [];
  
        const request = index.openCursor(IDBKeyRange.only(indexValue));
  
        request.onsuccess = (event: any) => {
          const cursor = event.target.result;
          if (cursor) {
            results.push(cursor.value);
            cursor.continue(); 
          } else {
            resolve(results); 
          }
        };
  
        request.onerror = () => {
          reject(new Error('Error fetching data by index'));
        };
      }).catch(error => {
        reject(new Error('Error opening database: ' + error));
      });
    });
  }
  

function getData(id: string): Promise<any> {
  return new Promise((resolve, reject) => {
    openIndexedDb().then(db => {
      const transaction = db.transaction('calls', 'readonly');
      const store = transaction.objectStore('calls');
      const request = store.get(id);

      request.onsuccess = event => {
        resolve((event.target as IDBRequest).result);
      };

      request.onerror = () => {
        reject(new Error('Error fetching data'));
      };
    });
  });
}
